import React, { useEffect, useState } from 'react'
import { Container, Button, Card, CardBody, Row,  } from 'reactstrap'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import LiveAccounts from './live'

// import ContestAcocunts from './competition_account'
import Breadcrumb from '../../layout/breadcrumb'
import './index.css'

const MT5account = () => {
    const { t } = useTranslation()
    const [nav_Check, setNav_Check] = useState('Live')

    return (
        <Container>  
            <Breadcrumb parent={t('dashboard.TradeAccount')}></Breadcrumb>

            <div className="d-none d-lg-block d-xl-block" style={{ display: 'flex', flexWrap: 'nowrap', marginBottom: '15px', justifyContent: 'flex-end' }}>
                <Row style={{ justifyContent: 'flex-end', marginTop:'30px', marginRight:'20px' }}>
                    <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '20px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014',
                                backgroundColor: 'white'

                            }}
                            color={nav_Check === 'Live' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}
                        >
                            {t('live.liveAccounts')}
                        </Button>
                    </div>

                    {/* <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '20px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Demo' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Demo
                        </Button>
                    </div> */}

                    {/* <div>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '200px',
                                height: '57px',
                                borderRadius: '20px',
                                fontSize: '20px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Contest' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Contest
                        </Button>
                    </div> */}

                </Row>
            </div>

            <div className='m-b-15 d-lg-none d-xl-none' style={{ display: 'flex', marginBottom: '15px', marginTop:'0px', justifyContent: 'center' }}>
                    <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                color: 'black',
                                width: 'auto',
                                height: '50px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014',
                                backgroundColor: 'white'

                            }}
                            color={nav_Check === 'Live' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}
                        >
                           {t('live.liveAccounts')}
                        </Button>
                    </div>

                    {/* <div style={{ marginRight: '10px' }}>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100px',
                                height: '50px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Demo' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Demo
                        </Button>
                    </div> */}
                    {/* <div>
                        <Button
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                width: '100%',
                                height: '50px',
                                borderRadius: '20px',
                                fontSize: '16px',
                                fontWeight: '500',
                                boxShadow: '0px 7px 20px #00000014'

                            }}
                            color={nav_Check === 'Contest' ? 'primary' : 'white'}
                            onClick={(e) => { setNav_Check(e.target.innerHTML) }}>
                            Contest
                        </Button>
                    </div> */}
                    
                </div>

            <Container className='mobile-css'>
                {nav_Check === 'Live' &&
                    <LiveAccounts />
                }
                {/* {nav_Check === 'Demo' &&
                    <DemoAccounts />
                } */}
                {/* {nav_Check === 'Contest' &&
                    <ContestAcocunts />
                } */}
            </Container>
        </Container>
    )
}
export default MT5account