import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Button, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import moment, { lang } from 'moment'
import SweetAlert from 'sweetalert2'
import { Link } from 'react-router-dom'
import UserService from '../../../../network/services/user'
import Auth from "../../../../network/services/auth"
import { useTranslation } from 'react-i18next'
import Select from "react-select";
import LanguageIcon from '../../../../language.png'

import { AlertCircle } from 'react-feather'

import { Country, State, City } from 'country-state-city';

const NewProfile = () => {
  const { t, i18n } = useTranslation()
  const [selectedPage, setSelectedPage] = useState(1)
  const [title, setTitle] = useState('')
  const [identity, setIdentity] = useState('')
  const [dob, setDob] = useState('')
  const [nationality, setNationality] = useState('')
  const [language, setLanguage] = useState('')
  const [education, setEducation] = useState('')
  const [employed, setEmployed] = useState('')

  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const userLanguage = sessionStorage.getItem('language')

  const countries = Country.getAllCountries();
  const states = State.getAllStates();
  const cities = City.getAllCities();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const changeLanguage = (userLanguage) => {
    i18n.changeLanguage(userLanguage)
    sessionStorage.setItem('language', userLanguage)
  }
  const checkValidateStep1 = () => {
    setSelectedPage(2)
  }

  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [address_1, setAddress_1] = useState('')
  const [address_2, setAddress_2] = useState('')
  const [zip, setZip] = useState('')

  const currentState = states.filter(state => state.countryCode === country)
  const currentCity = cities.filter(city => city.stateCode === state && city.countryCode === country)

  const checkPreviousValidateStep2 = () => {
    setSelectedPage(1)
  }

  const checkValidateStep2 = () => {
    setSelectedPage(3)
  }

  const [financial, setFinancial] = useState('')
  const [income, setIncome] = useState('')
  const [net_worth, setNet_worth] = useState('')
  const [objective, setObjective] = useState('')

  const checkPreviousValidateStep3 = () => {
    setSelectedPage(2)
  }

  const currentUser = useSelector((state) => state.user.user)
  const { register, handleSubmit, errors, formState } = useForm()
  const { isSubmitting } = formState

  const logOut = () => {
    Auth.logout()
  }

  const backHome = () => {
    window.location.href = '/dashboard'
  }

  const submitUpdate = async (data) => {
    // console.log(data['city'],'232')
    // console.log(data['country'],'232')
    // console.log(data['state'],'232')
    // console.log(data,'2')


    data['first_name'] = currentUser?.first_name ?? ''
    data['last_name'] = currentUser?.last_name ?? ''
    data['email'] = currentUser?.email ?? ''
    data['mobile'] = currentUser?.mobile ?? ''
    data['country'] = selectedCountry?.name ?? ''
    data['state'] = selectedState?.name ?? ''
    data['city'] = selectedCity?.name ?? city 

    if (data['country'] == '' || data['state'] == '' || data['city'] == undefined ){
      SweetAlert.fire({
        showClass: {
          popup: 'animate__animated animate__backInUp'
        },
        hideClass: {
          popup: 'animate__animated animate__backOutDown'
        },
        title: t("verifyAuth.PleaseFillInRegion"),
        text: t("verifyAuth.PleaseFillInRegionCompletely"),
        icon: 'error'
      })
    return
    }


    // debug
    // data['street'] = "1"
    // data['building'] = "1"
    // data['unit'] = "1"

    // console.log(data)
    try {
      const result = await UserService.createProfile(data)
      // console.log(result)

      if (result.profile) {
        SweetAlert.fire({
          showClass: {
            popup: 'animate__animated animate__backInUp'
          },
          hideClass: {
            popup: 'animate__animated animate__backOutDown'
          },
          title: t("verifyAuth.PersonalAreaInformation"),
          text: t("verifyAuth.PleaseProceedToSubmit"),
          icon: 'success'
        }).then(() => {
          window.location.href = '/dashboard'
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* <Dropdown isOpen={languageDropdownOpen} onMouseLeave={() => setLanguageDropdownOpen(false)} style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px', paddingRight: '30px', backgroundColor: '#f8f8f8' }}>
        <DropdownToggle onMouseEnter={() => setLanguageDropdownOpen(true)} id="langugeDropdown" style={{ paddingRight: '30px' }}  >
          <img src={LanguageIcon} alt="languageIcon" width='18px' height="18px" className="m-r-5" />
          {userLanguage === "en" && "EN"}
          {userLanguage === "zh" && "ZH"}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => changeLanguage('en')} >English</DropdownItem>
          <DropdownItem onClick={() => changeLanguage('zh')}>中文简体</DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
      <Form
        className="theme-form verify-auth-form"
        // onSubmit={handleSubmit(submitUpdate)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          padding: '0.75rem',
          height: '100vh'
        }}
      >
        {/* <Col lg='5' className="d-none d-md-block d-lg-block d-xl-block padding-verify-right">
          <Card style={{ height: selectedPage === 3 ? '502px' : '638px' }} >
            <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img
                  id='img-header-logo'
                  className="img-fluid for-light"
                  width='200px'
                  src={require("../../../../assets/images/logo/logoTradeHall.svg")}
                  alt=""
                />
                <p style={{ cursor: 'pointer' }} onClick={backHome}>{t("verifyAuth.back")}</p>
              </div>

              <div style={{ marginTop: '50px' }}>
                <h3>{t("verifyAuth.OpenNewAccount")}</h3>
                <p>{t("verifyAuth.CreateAnAccount")}</p>

                <p style={{ fontSize: '16px', color: '#C4A569' }}> {t("verifyAuth.Step2Fill")}</p>
                <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step1Deposit")}</p>
                <p style={{ fontSize: '16px', color: '#898989' }}> {t("verifyAuth.Step3Submit")}</p>
              </div>

              <div style={{ marginTop: 'auto' }}>
                <p style={{ marginBottom: '0' }}>{t("verifyAuth.RemindToUser")}</p>
                <p style={{ marginBottom: '5px' }}>1. {t("verifyAuth.TheBonusGiven")}</p>
                <p style={{ marginBottom: '5px' }}>2. {t("verifyAuth.OnceTransactionApproved")}</p>
                <p style={{ marginBottom: '5px' }}>3. {t("verifyAuth.PersonalDetails")}</p>
                <p style={{ marginBottom: '5px' }}>4. {t("verifyAuth.KYCNeedWithin")}</p>
              </div>

            </CardBody>
          </Card>
        </Col> */}

        <Col lg='5' className="padding-verify-left">
          <Card style={{ height: 'fit-content', display: selectedPage === 1 ? 'block' : 'none' }}>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='mb-4'>
                <img
                  id='img-header-logo'
                  className="img-fluid for-light"
                  width='200px'
                  src={require('../../../../assets/images/logo/ve-logo.png')}
                  alt=""
                />
              </div>
              <p className='d-md-none d-lg-none d-xl-none' style={{ fontSize: '16px', textAlign: 'center', marginTop: '20px' }}> Step 2. Fill In Personal Information</p>
              <h5 style={{ textTransform: 'none' }}>{t("user.PersonalInformation")}</h5>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.Title")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="select"
                  name="title"
                  innerRef={register({ required: true })}
                  defaultValue=""
                  onChange={(e) => { setTitle(e.target.value) }}
                >
                  <option value="" disabled>
                    {t("user.Select")}
                  </option>
                  <option value="mr">{t("user.Mr")}</option>
                  <option value="ms">{t("user.MsMrs")}</option>
                </Input>
                <span style={{ color: 'red' }}>{errors.title && t("user.TitleIsRequired")}</span>
              </FormGroup>

              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.IdentityCardPassportNumber")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="identification_number"
                  innerRef={register({ required: true })}
                  defaultValue=""
                  onChange={(e) => { setIdentity(e.target.value) }}
                  placeholder="e.g. 901111012345"
                >
                </Input>
                <span style={{ color: 'red' }}>{errors.identity && t("user.IdentityIsRequired")}</span>
              </FormGroup>

              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.DateBirth")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="date"
                  name="dob"
                  placeholder="yyyy-mm-dd"
                  innerRef={register({
                    required: true,
                    validate: (value) =>
                      moment().diff(value, 'years') >= 18 && moment().diff(value, 'years') <= 99
                  })}
                  onChange={(e) => { setDob(e.target.value) }}
                />
                <span style={{ color: 'red' }}>{errors.dob && t("user.MustBe18Years")}</span>
              </FormGroup>

              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.CountryResidence")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>

                <Select
                  options={Country.getAllCountries()}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCountry}
                  onChange={(item) => {
                    setSelectedCountry(item);
                  }}
                />

                {/* <Input
                  className="form-control"
                  type="select"
                  name="country"
                  innerRef={register({ required: true })}
                  defaultValue=" "
                  onChange={(e) => {
                    setCountry(e.target.value)
                  }}
                >
                  {countries.map((option, index) => (
                    <option key={index} value={option.isoCode}>

                      {option.name}
                    </option>
                  ))}
                </Input> */}

                {/* <span style={{ color: 'red' }}>{selectedCountry ? '' : t("user.CountryRequired")}</span> */}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.ProvinceState")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Select
                  options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedState}
                  onChange={(item) => {
                    setSelectedState(item);
                  }}
                />

                {/* <Input
                  className="form-control"
                  type="select"
                  name="state"
                  innerRef={register({ required: true })}
                  onChange={(e) => { setState(e.target.value) }}
                  disabled={country === ''}
                >
                  {currentState.map((option, index) => (
                    <option key={index} value={option.isoCode}>
                      {option.name}
                    </option>
                  ))}

                </Input> */}

                {/* <span style={{ color: 'red' }}>{selectedState ? '' : t("user.StateRequired")}</span> */}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.City")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                {City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode)
                     == '' ?
                  <Input
                    className="form-control"
                    type="text"
                    name="city"
                    innerRef={register({ required: true })}
                    defaultValue=""
                   onChange={(e) => { setCity(e.target.value) }}
                    placeholder="e.g. Los Angeles."
                  >
                  </Input>

                  :
                  <Select
                    options={City.getCitiesOfState(
                      selectedState?.countryCode,
                      selectedState?.isoCode
                    )}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["name"];
                    }}
                    value={selectedCity}
                    onChange={(item) => {
                      setSelectedCity(item);
                    }}
                  />

                }
                {/* <Select
                  options={City.getCitiesOfState(
                    selectedState?.countryCode,
                    selectedState?.isoCode
                  )}
                  getOptionLabel={(options) => {
                    return options["name"];
                  }}
                  getOptionValue={(options) => {
                    return options["name"];
                  }}
                  value={selectedCity}
                  onChange={(item) => {
                    setSelectedCity(item);
                  }}
                /> */}
                {/* <Input
                  className="form-control"
                  type="select"
                  name="city"
                  innerRef={register({ required: true })}
                  onChange={(e) => { setCity(e.target.value) }}
                  disabled={state === ''}
                >
                  {currentCity.map((option, index) => (
                    <option key={index} value={option.isoCode}>

                      {option.name}
                    </option>
                  ))}
                </Input> */}

                <span style={{ color: 'red' }}>{errors.city && t("user.CityRequired")}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.AddressLine")} 1<span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="address_1"
                  innerRef={register({ required: true })}
                  onChange={(e) => { setAddress_1(e.target.value) }}
                />
                <span style={{ color: 'red' }}>{errors.address_1 && t("user.AddressRequired")}</span>
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">{t("user.AddressLine")} 2</Label>
                <Input
                  className="form-control"
                  type="text"
                  name="address_2"
                  innerRef={register({ required: false })}
                  onChange={(e) => { setAddress_2(e.target.value) }}
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label pt-0">
                  {t("user.ZipCode")}
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <Input
                  className="form-control"
                  type="text"
                  name="zip"
                  innerRef={register({ required: true })}
                  onChange={(e) => { setZip(e.target.value) }}
                />
                <span style={{ color: 'red' }}>{errors.zip && t("user.ZipRequired")}</span>
              </FormGroup>

              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className="m-t-20" >

                <Button
                  color="primary btn-block"
                  type="submit"
                  style={{ maxWidth: 150 }}
                  onClick={handleSubmit(submitUpdate)}
                >
                  {isSubmitting ? t("user.Loading") : t("user.Submit")}
                </Button>

                {/* <button
                  type='button'
                  color="primary"
                  className="verify-auth-button"
                  style={{ maxWidth: 150, borderRadius: '5px' }}
                  disabled={!title || !identity || !dob }
                  onClick={() => checkValidateStep1()}
                >
                  {t("verifyAuth.Next")}
                </button> */}
              </div>
            </CardBody>
          </Card>


        </Col>
      </Form>
    </>
  )
}

export default NewProfile
